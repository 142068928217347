export enum ApPayType{
    upTo300="Até R$ 300 mil",
    upTo500="R$ 300 mil a R$ 500 mil",
    upTo900="R$ 500 mil a R$ 900 mil",
    above900="Acima de R$ 900 mil"
}
export enum EntryValue{
    cashPay="À vista",
    above50="Entrada acima de 50%",
    upTo50="Entrada até 50%",
    upTo25="Entrada até 25%",
    upTo10="Entrada até 10%",
    noDownPay="Sem entrada"
}

export function stringToApPayType(str:string){
    for(let v of Object.entries(ApPayType)){
         if(v[1]==str){
            return v[0]
         }
    }
}

export function stringToEntryValue(str:string){
    for(let v of Object.entries(EntryValue)){
         if(v[1]==str){
            return v[0]
         }
    }
}
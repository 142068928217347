import '../index.css';
export function Header(){
    return (<div className='header'>
        <img src='/img/logo.png' className='header_logo'/>
        <div className='header_text'>
        <h1>Itapema ao seu alcance</h1>
        <h5>Preencha o formulário para descobrir as melhores oportunidades de apartamentos em Itapema.
        </h5>
        </div>
    </div>)
}
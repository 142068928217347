import 'survey-core/defaultV2.min.css';
import { Model, PageModel } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useEffect, useState } from 'react';
import { justNumbers } from '../utils/numbers';
import { themeJson } from './theme';
import '../index.css'

import {  ApPayType, EntryValue, stringToApPayType, stringToEntryValue } from './types';
import { sendServerRequest } from '../utils/sendform';
import { Header } from './header';
const FORM_STORAGE_NAME='im_form';
const surveyJson = {
    "showProgressBar": "belowHeader",
    "progressBarType": "pages",
    "progressBarShowPageNumbers": true,
    "progressBarShowPageTitles": true,
    "title": "Itapema ao seu alcance",
    "description": "Preencha o formulário para descobrir as melhores oportunidades de apartamentos em Itapema.",
    "logo": "/img/logo.png",
    //"logoWidth": "auto",
 "logoHeight": "150",
    pagePrevText: "Anterior",
  pageNextText: "Próximo",
  completeText: "Concluir",
  startSurveyText: "Iniciar",
    "completedHtml":``,
    pages: [{
        "navigationTitle": "Informações Básicas",
        elements: [{
            name: "name",
            title: "Qual é o seu nome?",
            type: "text",
            requiredErrorText:'Digite seu nome.',
            autocomplete:'name',
            isRequired: true,
        },
        {
            name:'age',
            title:"Qual é a sua idade?",
            type:"text",
            inputType:'number',
            isRequired:true,
            requiredErrorText:'Digite a sua idade.',
            min:14,
            max:100,
            validators: [{
                "type": "numeric",
                "text": "Digite uma idade valida.",
                "minValue": 14,
                "maxValue": 100
            }]
        }
       ]
    },
    {
        "navigationTitle": "Faixa de valores",
        elements: [{
            name: "ap_value",
            title: "Qual é a faixa de valor que você deseja investir?",
            type: "radiogroup",
            "isRequired": true,
            requiredErrorText:'Selecione uma opção.',
            "colCount": 1,
            choices: Object.values(ApPayType)
        },],
    }, {
        "navigationTitle": "Entrada",
        elements: [{
            name: "entry_value",
            title: "Qual é o valor de entrada que você deseja aportar no apartamento?",
            type: "radiogroup",
            "isRequired": true,
            "colCount": 1,
            requiredErrorText:'Selecione uma opção.',
            choices: Object.values(EntryValue)
        }],
    },
    {
        "navigationTitle": "Contato",
        elements: [{
            name: "phone",
            title: "Seu WhatsApp",
            description:'Informe seu número de WhatsApp para que nossa equipe entre em contato com você.',
            type: "text",
            "isRequired": true,
            "inputType": "tel",
            "autocomplete": "tel",
            
        }],
    },
   ]
};
function resetForm(){
    window.localStorage.removeItem(FORM_STORAGE_NAME);
    window.location.reload();
}
function getSu(complete:Function){
    const su=new Model(surveyJson);
   //@ts-ignore
   su.applyTheme(themeJson);
   su.onAfterRenderPage.add(()=>{
    loadSavedForm(su);
   })
   su.onCurrentPageChanged.add((s,o)=>{
        updateStorageFromPage(s,o.oldCurrentPage)
   });
   su.onComplete.add(async (s,p)=>{
      const keys=Object.entries(s.data);
      updateStorageForm(keys,true);

      complete();
   })
   
   su.onValidateQuestion.add((s,o)=>{
       if(o.name=='phone'){
          let value=s.getValue('phone');
          let phone=justNumbers(value);
          if(phone.length<10||phone.length>13){
              o.error="Digite um número valido. Inclua o DDD";
          }      
       }
   })
   return su;
}
export function ImoveisForm(){
   const [isComplete,setIsComplete]=useState(false);
   const [start,setStart]=useState(false);
   const [isLoading,setIsLoading]=useState(false);
   useEffect(()=>{
      let st=getStorage();
      if(!st||!st.isComplete){
          setStart(true);
          return;
      }
      setIsComplete(true);
   },[]);
   useEffect(()=>{
      if(isComplete&&!start){
          setStart(true);
      }
   },[isComplete]);
   useEffect(()=>{
      if(isLoading){
           const items=getStorage().items;
           items.ap_value=stringToApPayType(items.ap_value);
           items.entry_value=stringToEntryValue(items.entry_value);
           sendServerRequest('POST','/server/clients/register',items).then(()=>{
              setIsComplete(true);
              setIsLoading(false);
           }).catch(e=>{
            console.log(e);
           })
      }
   },[isLoading])
   return (<div>
       <Header/>
       {isLoading?<Loading/>:null}
      {!start?null:<>
        {!isComplete?!isLoading?<Survey model={getSu(()=>setIsLoading(true))}/>:null:
      <div style={{justifyItems:"center",marginTop:'130px'}}>
        <h1 style={{color: "rgb(249 253 243)",textShadow:"2px 2px rgb(13 24 29)"}}>
            Cadastro concluído! Em breve, um de nossos corretores entrará em contato com você.</h1>
            <button onClick={resetForm} className='restart_button' >Refazer Cadatro</button>
        </div>
    }
      </>}
    
    
   </div>)
}

function getStorage(){
    let st:any=window.localStorage.getItem(FORM_STORAGE_NAME);
    if(!st){
       return;
    }  
    st=JSON.parse(st);
    return st;
}
function loadSavedForm(m:Model){
    let st=getStorage();
    if(!st)
         return;
    if(!st.items){
        window.localStorage.removeItem(FORM_STORAGE_NAME);
        return;
    }
    const keys=Object.keys(st.items);
    for(let key of keys){
        m.setValue(key,st.items[key]);
    }
}

function updateStorageFromPage(m:Model,p:PageModel){
    const elms=p.getElementsInDesign();
    let set:Array<[string,string]>=[];
    for(let e of elms){
      set.push([e.name,m.getValue(e.name)]);
    }
    updateStorageForm(set);
}
function updateStorageForm(items:Array<[string,any]>,complete?:boolean){
    let st:any=window.localStorage.getItem('im_form');
    if(!st){
        st={
            isComplete:false,
            items:{}
        };
    }else{
        st=JSON.parse(st);
    }
    for(let i of items){
        st.items[i[0]]=i[1];
    }
    st.isComplete=Boolean(complete);
    window.localStorage.setItem('im_form',JSON.stringify(st));
}
function Loading(){
    return (
        <>
        <div className='loader loader_fixed'>
        </div>
        <div className='loader_fixed' style={{marginTop:'40px',color:'#fff'}}>
        <p>Enviando Formulário</p>
        </div>
        <div className='loading_base'></div>
        </>
    )
}
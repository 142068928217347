export const themeJson = {
    "backgroundOpacity": 0,
    "cssVariables": {
    "--sjs-header-backcolor":"rgb(76 138 205 / 23%)",  
    "--sjs-general-backcolor-dim":"rgba(255, 255, 255, 0.0)",  
    "--sjs-general-backcolor": "rgba(255, 255, 255, 0.0)",
    "--sjs-font-pagetitle-color":"#32375e",
    "--sjs-font-headertitle-color":"#32375e",
    "--sjs-font-surveytitle-color":"#32375e",
    "--sjs-questionpanel-backcolor": "rgba(255, 255, 255, 0.8)",
    "--sjs-primary-backcolor": "rgba(4, 91, 185, 1)",
    "--sjs-primary-backcolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-primary-backcolor-dark": "rgba(4, 91, 185, 0.75)",
    "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-general-forecolor-light":"rgba(255, 255, 255, 1)",
    "--sjs-font-questiondescription-color":"rgb(18 1 1 / 71%)",
  },
   "themeName": "default",
  "colorPalette": "light",
  "isPanelless": false,
  "header": {
    "display":"none",
   "height": 80,
    "textAreaWidth": 500,
    //"inheritWidthFrom": "survey",
    "logoPositionX": "left",
    "logoPositionY": "middle",
    "titlePositionX": "right",
    "titlePositionY": "middle",
    "descriptionPositionX": "right",
    "descriptionPositionY": "middle"
  },

  "headerView": "advanced"
  
 
}
export function hasJustNumber(s:string){
  for(let i=0;i<s.length;i++){
          const ask=s.charCodeAt(i);
          if(ask<48||ask>57)
            return false;
  }
  return true;
}
export function justNumbers(s:string){
    let ret='';
    for(let i=0;i<s.length;i++){
        const ask=s.charCodeAt(i);
        if(ask<48||ask>57)
            continue;
        ret+=s[i];
    }
    return ret;
}
export async function sendServerRequest(method:'POST'|'GET',path:string,data?:Object|string|FormData):Promise<{status:number,data:any,target:any}>{
    try{
        const request = new XMLHttpRequest();
        request.open(method, path);  
        let sendData=data;
        if(!(data instanceof FormData)){
            request.setRequestHeader("Content-type",'application/json');
            if(typeof(data)=='string'){
            
            }else{
               sendData=JSON.stringify(data);
            }
        }
        //@ts-ignore
        request.send(sendData);
        return new Promise((resolve,reject)=>{
            request.onreadystatechange=orsc;
            function orsc(r:any){
               const target=r.currentTarget;
               if(!target)
                 return;
               if(target.readyState==4){
                   if(target.status>=200 && target.status<300){
                       let json=undefined;
                       try{
                           json=target.responseText?JSON.parse(target.responseText):null;
                       }catch(e){

                       }
                       const data=json;
                       resolve({status:target.status,data:data,target:target});
                   }
                   reject({target:target});
               }                
            }
        });
    }catch(e){
         throw e;
    }
}